import { push } from 'connected-react-router';
import * as api from '../../../api';
import * as azureB2CApi from '../../../api/azureB2C';
import * as tileApi from '../../../api/tiles';
import {
  putAuthTokens,
  deleteAuthTokens,
  isAzureB2CAuth,
  setAzureB2CAuth,
  setAzureB2CPolicyName,
} from '../../../localStorage/authentication';
import { getUserInfo } from '../../AppInitializer/actions';

export const ISSUE_TOKEN_REQUEST = 'login/AUTH_REQUEST';
export const ISSUE_TOKEN_RESPONSE = 'login/AUTH_RESPONSE';
export const LOGIN_FAILED = 'login/LOGIN_FAILED';
export const issueAzureB2CTokensAction = (code) => async (dispatch) => {
  dispatch({ type: ISSUE_TOKEN_REQUEST });

  try {
    const response = await azureB2CApi.issueAzureB2CTokens(code, azureB2CApi.AZURE_B2C_POLICY_LOGIN);
    if (response.status === 200) {
      setAzureB2CAuth();
      putAuthTokens(response.data);
      setAzureB2CPolicyName(azureB2CApi.AZURE_B2C_POLICY_LOGIN);
      dispatch({ type: ISSUE_TOKEN_RESPONSE });
      return true;
    }
  } catch (e) {
    if (e.response && e.response.status === 400 && e.response.data.error === 'invalid_grant') {
      dispatch({ type: LOGIN_FAILED, error: e.response.data.description });
      return false;
    }
  }

  dispatch({ type: LOGIN_FAILED, error: 'unknown' });
  return false;
};

export const loginWithAzureB2CAction = (code, redirectTo) => async (dispatch) => {
  tileApi.resetAllTilesLastRefreshed();
  if (await issueAzureB2CTokensAction(code)(dispatch)) {
    await getUserInfo()(dispatch);
    if (redirectTo) {
      dispatch(push(redirectTo));
    }
  }
};

export const login = (username, password) => async (dispatch) => {
  tileApi.resetAllTilesLastRefreshed();
  if (await issueToken(username, password)(dispatch)) {
    await getUserInfo()(dispatch);
    return true;
  }
  return false;
};

const tryParse = (str) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return null;
  }
};

export const VALIDATE_CREDENTIALS_REQUEST = 'login/VALIDATE_CREDENTIALS_REQUEST';
export const VALIDATE_CREDENTIALS_RESPONSE_SUCCEEDED = 'login/VALIDATE_CREDENTIALS_RESPONSE_SUCCEEDED';
export const VALIDATE_CREDENTIALS_RESPONSE_FAILED = 'login/VALIDATE_CREDENTIALS_RESPONSE_FAILED';
export const validateUserCredentials = (username, password) => async (dispatch) => {
  dispatch({ type: VALIDATE_CREDENTIALS_REQUEST });
  try {
    const response = await api.validateUserCredentials(username, password);
    if (response.data.isEmailConfirmed) {
      dispatch({ type: VALIDATE_CREDENTIALS_RESPONSE_SUCCEEDED, data: response.data });
    } else {
      dispatch({ type: LOGIN_FAILED, error: 'email_not_confirmed' });
    }
    return { isOk: true, userId: response.data.id, isEmailConfirmed: response.data.isEmailConfirmed };
  } catch (e) {
    if (e.response && e.response.status === 400) {
      const error = tryParse(e.response.data.description) || e.response.data.description;
      dispatch({ type: VALIDATE_CREDENTIALS_RESPONSE_FAILED, error });
      return { isOk: false, error };
    }
  }

  dispatch({ type: VALIDATE_CREDENTIALS_RESPONSE_FAILED, error: 'unknown' });
  return { isOk: false };
};

export const VALIDATE_OLD_USER_CREDENTIALS_REQUEST = 'login/VALIDATE_OLD_USER_CREDENTIALS_REQUEST';
export const VALIDATE_OLD_USER_CREDENTIALS_RESPONSE_SUCCEEDED =
  'login/VALIDATE_OLD_USER_CREDENTIALS_RESPONSE_SUCCEEDED';
export const validateOldUserCredentials = (username, password) => async (dispatch) => {
  if (await checkUserEmail(username)) {
    dispatch({ type: LOGIN_FAILED, error: 'invalid_username_or_password' });
    return;
  }
  dispatch({ type: VALIDATE_OLD_USER_CREDENTIALS_REQUEST });
  try {
    const response = await api.validateOldUserCredentials(username, password);
    dispatch({ type: VALIDATE_OLD_USER_CREDENTIALS_RESPONSE_SUCCEEDED, data: response.data });
    return true;
  } catch (e) {
    if (e.response && e.response.status === 404) {
      dispatch({ type: LOGIN_FAILED, error: 'invalid_username_or_password' });
      return false;
    }
  }

  dispatch({ type: LOGIN_FAILED, error: 'unknown' });
  return false;
};

export const MIGRATE_OLD_USER_REQUEST = 'login/MIGRATE_OLD_USER_REQUEST';
export const MIGRATE_OLD_USER_RESPONSE_SUCCEEDED = 'login/MIGRATE_OLD_USER_RESPONSE_SUCCEEDED';
export const MIGRATE_OLD_USER_RESPONSE_FAILED = 'login/MIGRATE_OLD_USER_RESPONSE_FAILED';
export const migrateOldUser = (oldUserId, acceptedTosVersion, acceptedPrivacyPolicyVersion) => async (dispatch) => {
  dispatch({ type: MIGRATE_OLD_USER_REQUEST });
  try {
    await api.migrateOldUser(oldUserId, acceptedTosVersion, acceptedPrivacyPolicyVersion, null);
    dispatch({ type: MIGRATE_OLD_USER_RESPONSE_SUCCEEDED });
    return true;
  } catch (e) {
    if (e.response && e.response.status === 400) {
      dispatch({ type: MIGRATE_OLD_USER_RESPONSE_FAILED, error: e.response.data.description });
      return false;
    }
  }

  dispatch({ type: MIGRATE_OLD_USER_RESPONSE_FAILED, error: 'unknown' });
  return false;
};

export const SET_UP_AGREEMENTS_REQUEST = 'login/SET_UP_AGREEMENTS_REQUEST';
export const SET_UP_AGREEMENTS_RESPONSE_SUCCEEDED = 'login/SET_UP_AGREEMENTS_RESPONSE_SUCCEEDED';
export const SET_UP_AGREEMENTS_RESPONSE_FAILED = 'login/SET_UP_AGREEMENTS_RESPONSE_FAILED';
export const setUpUserAgreements =
  (username, password, acceptedTosVersion, acceptedPrivacyPolicyVersion) => async (dispatch) => {
    dispatch({ type: SET_UP_AGREEMENTS_REQUEST });
    try {
      await api.setUpAgreements(username, password, acceptedTosVersion, acceptedPrivacyPolicyVersion);
      dispatch({ type: SET_UP_AGREEMENTS_RESPONSE_SUCCEEDED });
      return true;
    } catch (e) {
      if (e.response && e.response.status === 400 && e.response.data.error === 'invalid_grant') {
        dispatch({ type: SET_UP_AGREEMENTS_RESPONSE_FAILED, error: e.response.data.description });
        return false;
      }
    }

    dispatch({ type: SET_UP_AGREEMENTS_RESPONSE_FAILED, error: 'unknown' });
    return false;
  };

export const issueToken = (username, password) => async (dispatch) => {
  dispatch({ type: ISSUE_TOKEN_REQUEST });

  try {
    const response = await api.issueAuthToken(username, password);
    if (response.status === 200) {
      putAuthTokens(response.data);
      dispatch({ type: ISSUE_TOKEN_RESPONSE });
      return true;
    }
  } catch (e) {
    if (e.response && e.response.status === 400 && e.response.data.error === 'invalid_grant') {
      dispatch({ type: LOGIN_FAILED, error: e.response.data.description });
      return false;
    }
  }

  dispatch({ type: LOGIN_FAILED, error: 'unknown' });
  return false;
};

export const LOGOUT_USER = 'login/LOGOUT_USER';
export const logoutUser = (allowRedirect = true) => {
  const isAzureB2C = isAzureB2CAuth();
  deleteAuthTokens();

  if (isAzureB2C) {
    window.location.href = azureB2CApi.getAzureB2CLogoutLink();
    setAzureB2CPolicyName('');

    return false;
  }

  return { type: LOGOUT_USER, allowRedirect };
};

const checkUserEmail = async (email) => {
  const {
    data: { access_token },
  } = await api.issueAnonymousAuthToken();
  try {
    await api.findUserByEmail(access_token, email);
    return true;
  } catch (e) {
    return false;
  }
};
