import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { languageSelected, setLastUsedLanguage } from '../../../../LanguageSelector/actions';
import { languageOptions } from '../../../../../../constants/languages';

import { sortArrayByLocaleCompare } from '../../../../../../utils';

import './customlanguageselector.scss';

const CustomLanguageSelector = ({
  className,
  languageSelected,
  setLastUsedLanguage,
  selectedLanguage,
  showShortCodes,
  showIcon = true,
}) => {
  const changeLanguage = (e) => {
    e.preventDefault();
    const { value } = e.target;

    languageSelected(value);
    setLastUsedLanguage(value);
  };

  const customClasses = className || '';
  const sortedLanguageOptions = sortArrayByLocaleCompare(languageOptions, 'label');

  return (
    <div className={'customLanguageSelector ' + customClasses}>
      <select
        className="languageSelector__selectControl"
        id="language-selector"
        onChange={(e) => changeLanguage(e)}
        value={selectedLanguage}
        aria-label="Select language"
      >
        {sortedLanguageOptions.map((item) => {
          return (
            <option key={item.value} value={item.value}>
              {showShortCodes ? item.value.toUpperCase() : item.label}
            </option>
          );
        })}
      </select>
      {showIcon && <FontAwesomeIcon icon="globe-americas" size="2x" id="language-globe" />}
    </div>
  );
};

CustomLanguageSelector.propTypes = {
  className: PropTypes.string,
  selectedLanguage: PropTypes.string,
  languageSelected: PropTypes.func.isRequired,
  setLastUsedLanguage: PropTypes.func.isRequired,
  showShortCodes: PropTypes.bool,
  showIcon: PropTypes.bool,
};

const mapStateToProps = ({ language: { selectedLanguage } }) => ({
  selectedLanguage,
});

const mapDispatchToProps = {
  languageSelected,
  setLastUsedLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomLanguageSelector);
